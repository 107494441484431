import React from 'react'
import { Helmet } from 'react-helmet'
import styled from "styled-components"
import Layout from '../layouts/simple.js'
import '../styles/index.scss'
import Healthcare from '../images/healthcare-chair.jpg'
import Education from '../images/classroom-setting.jpg'
import Government from '../images/government-office.jpg'
import Business from '../images/business.jpg'
import Office from '../images/home.jpg'
import Banner from '../images/banner-left.jpg'

const HeroGridWrapper = styled.div`
    display: grid;
    grid-template-columns: 600px auto;

    @media only screen and (max-width: 1600px) {
        grid-template-columns: 400px auto;
    }

    @media only screen and (max-width: 1200px) {
        grid-template-columns: 0px auto;
    }
`

const HeroTextWrapper = styled.div`
    width: 100%;
    max-width: 1200px;
`

const HeroButtonWrapper = styled.div`
    width: 100%;
    max-width: 500px;
`

const HeroButtons = styled.div`
    display: flex;
    gap: 20px;

    @media only screen and (max-width: 440px) {
        flex-direction: column;
    }
`

const HeroButton = styled.a`
    padding: 12px 30px;
`

const Home = () => {
    return (
        <div className="page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dundas Interiors - Your Montana Herman Miller Dealer</title>
                <link rel="canonical" href="dundasinteriors.com" />
            </Helmet>
            <Layout>
                <section>
                    <div>
                        <HeroGridWrapper className='bg-black'>
                            <div className='align-self-stretch'>
                                <img src={Banner} alt="Herman Miller and Knoll merge" className='h-100' />
                            </div>
                            <div>
                                <HeroTextWrapper className='p-4 p-md-5 text-white'>
                                    <p className='fs-4'>“We're excited to introduce MillerKnoll, a collective of dynamic brands coming together to design the world we live in.”</p>
                                    <p className='fs-4 text-end fst-italic'>-MillerKnoll president and CEO Andi Owen</p>
                                    <HeroButtonWrapper className='mt-5'>
                                        <p>From sourcing to delivery, Dundas Interiors works with your team to bring style and comfort to your space.</p>
                                        <HeroButtons>
                                            <HeroButton className="btn btn-white" href="/solutions" role="button">How We Help</HeroButton>
                                            <HeroButton className="btn btn-outline-white" href="/contact" role="button">Contact Us</HeroButton>
                                        </HeroButtons>
                                    </HeroButtonWrapper>
                                </HeroTextWrapper>
                            </div>
                        </HeroGridWrapper>
                    </div>
                </section>
                {/* <section className="hero index">
                    <div className="hero-background hero-background-tall">
                        <div className="container">
                            <div className="hero-content">
                                <h1>Your Montana Office Furniture Dealer</h1>
                                <h3>From sourcing to delivery, Dundas Interiors works with your team to bring style and comfort to your office.</h3>
                                <div className="hero-buttons mt-24">
                                    <Link to='/solutions' className="button button-white">How we help</Link>
                                    <Link to='/contact' className="button button-white-inverse ml-24">Contact us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="py-60">
                    <div className="container column-five">
                        <div className="solution">
                            <div className="solution-placeholder"><img src={Healthcare} alt="nemschoff brand patient chair" /></div>
                            <p className='mt-1'>
                                Healthcare
                            </p>
                        </div>
                        <div className="solution">
                            <div className="solution-placeholder"><img src={Education} alt="a college classroom environment" /></div>
                            <p className='mt-1'>
                                Education
                            </p>
                        </div>
                        <div className="solution">
                            <div className="solution-placeholder"><img src={Government} alt="conference room" /></div>
                            <p className='mt-1'>
                                Government
                            </p>
                        </div>
                        <div className="solution">
                            <div className="solution-placeholder"><img src={Business} alt="cubicles with privacy walls" /></div>
                            <p className='mt-1'>
                                Business
                            </p>
                        </div>
                        <div className="solution">
                            <div className="solution-placeholder"><img src={Office} alt="cubicles with privacy walls" /></div>
                            <p className='mt-1'>
                                Home
                            </p>
                        </div>
                    </div>
                </section>
                {/* <section className="pb-60">
                    <div className="container column-four-six">
                        <div className="center-vertical">
                            <div>
                                <h2>A Home Office Designed For Productivity</h2>
                                <h3>When your home office is central to your career, you need the quality and comfortable style we’ve been delivering for a century. Adjustable desks and superior seating are our specialty.</h3>
                            </div>
                        </div>
                        <div className="image-container"><img src={Office} alt="work from home desk and chair"></img></div>
                    </div>
                </section> */}
                {/*}
                <section className="py-60" id="home-page-about">
                    <div className="container">
                        <div className="column-two">
                            <div>
                                <h2 className="">The Dundas Interiors Team</h2>
                                <Link to="/" className="link-reset"><h3 className="inline-block">Contact our amazing team</h3></Link>
                            </div>
                            <div>
                                <h3 className="">Whether by blood or by choice, everyone at Dundas Interiors is family. We believe our people are the reason we've been in business 100 years and we couldn't have done it without them.</h3>
                            </div>
                        </div>
                        <img src={Team} alt="" className="mt-48"/>
                    </div>
                </section>
                */}
            </Layout>
        </div>
    )
}

export default Home